html {
    scroll-behavior: smooth;
    font-family: 'Lato', Arial, serif !important;
}
/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

.box {
    @apply bg-black min-w-full h-32 min-h-full rounded;
}

@layer base {
   
    p {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        line-height: 25px;
    }
  
    h1 {
        margin: 0 !important;
    }
}

@media only screen and (max-width: 568px) {
  
}

@layer components {
    .hidden-tw {
        @apply hidden;
    }
}